<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-margin-file mt-8">
      <!-- FILE -->
      <template v-if="show == 1">
        <v-row justify="center">
          <v-col cols="12"> </v-col>
          <v-col cols="8" class="text-left">
            <h1 class="upload-file__title">Condición de comisiones</h1>
          </v-col>
        </v-row>
        <v-row v-if="!form.file" justify="center">
          <v-col cols="8" class="text-left">
            <div class="flex md12">
              <div id="app2" v-cloak @drop.prevent="addFile" @dragover.prevent>
                <input
                  ref="excel-upload-input"
                  class="excel-upload-input"
                  type="file"
                  @change="handleClick"
                />
                <center class="mt-15 pt-3">
                  <img src="../../assets/icon/upload.svg" />
                </center>
                <center class="mt-8">
                  <p class="txt-subtitle-commisions pb-0 mb-0">
                    Arrastre el archivo o
                    <v-btn
                      @click="handleUpload"
                      class="pl-1 ml-1"
                      color="#466BE3"
                      text
                      ><u>Busque en su computador</u></v-btn
                    >
                  </p>
                  <p class="txt-commisions-ligth mb-1">
                    Debe ser un archivo .PDF,
                  </p>
                  <p class="txt-commisions-ligth">
                    El peso límite del archivo es de 1 MB
                  </p>
                </center>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row v-else justify="center">
          <v-col cols="8">
            <v-card class="card-file-upload" elevation="0">
              <v-row>
                <v-col cols="1">
                  <img
                    width="53"
                    class="mt-3 ml-2"
                    src="../../assets/icon/upload.svg"
                  />
                </v-col>
                <v-col cols="10" class="text-left">
                  <p class="mb-1 mt-3 txt-info-file-title">
                    {{ infoFile.name }}
                  </p>
                  <p class="mb-2 txt-info-file-subtitle">
                    {{ infoFile.size }} MB
                  </p>
                  <v-progress-linear
                    class="mb-3"
                    color="#466be3"
                    :height="5"
                    :indeterminate="loadFile"
                    stream
                    value="100"
                  ></v-progress-linear>
                </v-col>
                <v-col cols="1">
                  <v-btn @click="form.file = null" class="mt-4" fab icon small>
                    <v-icon color="#626262"> mdi-close-circle-outline </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="4">
            <v-btn
              @click="show == 1 && form.file ? (form.file = false) : go()"
              style="background-color: #fff"
              block
              rounded
              outlined
              color="#466BE3"
            >
              atras
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-hover v-slot="{ hover }">
              <v-btn
                @click="nextForm()"
                block
                rounded
                style="color: #fff"
                :style="{
                  'background-color': hover ? '#324CA1' : '#466BE3',
                }"
              >
                Siguiente
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </template>
      <!-- DATA -->
      <template v-if="show == 2">
        <v-row justify="center">
          <v-col cols="12"> </v-col>
          <v-col cols="8" class="text-left">
            <h1 class="upload-file__title">Condición de comisiones</h1>
            <v-form ref="form">
              <v-autocomplete
                v-model="form.clientId"
                :items="listClient"
                label="Seleccione el cliente *"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Este campo es requerido']"
              ></v-autocomplete>
              <v-autocomplete
                v-model="form.campaignId"
                :items="listCampaign"
                label="Seleccione la campaña *"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Este campo es requerido']"
              ></v-autocomplete>
              <v-autocomplete
                v-model="form.profileId"
                :items="listProfile"
                label="Seleccione el cargo *"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Este campo es requerido']"
              ></v-autocomplete>
              <v-autocomplete
                v-model="form.contractorId"
                :items="listContractor"
                label="Seleccione contratante *"
                :rules="[(v) => !!v || 'Este campo es requerido']"
              ></v-autocomplete>
            </v-form>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="4">
            <v-btn
              @click="previewFile()"
              style="background-color: #fff"
              block
              rounded
              outlined
              color="#466BE3"
            >
              atras
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-hover v-slot="{ hover }">
              <v-btn
                @click="save()"
                block
                rounded
                style="color: #fff"
                :style="{
                  'background-color': hover ? '#324CA1' : '#466BE3',
                }"
              >
                CARGAR CONDICIONES
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </template>
    </div>

    <!-- MOBILE -->
    <div class="mb-sm-margin-file">
      <v-row justify="center" class="ml-0 mr-0 px-3 my-4">
        <v-col style="text-align: left" cols="12" class="py-4 px-0 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label
                style="padding-top: 5%"
                class="text-apps-blue-sm txt-capitalize"
              >
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="text-apps-blue-sm">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
            para adjuntar documento
          </p>
        </v-col>
        <v-col cols="12" class="py-0 px-0 text-left">
          <div
            v-if="!form.file"
            id="app2"
            v-cloak
            @drop.prevent="addFile"
            @dragover.prevent
          >
            <input
              ref="excel-upload-input"
              class="excel-upload-input"
              type="file"
              @change="handleClick"
            />
            <center class="mt-3 pt-4">
              <img src="../../assets/icon/upload.svg" />
            </center>
            <center class="mt-3">
              <p class="txt-subtitle-commisions pb-0 mb-0">
                Adjunte el documento
                <v-btn
                  @click="handleUpload"
                  class="pl-1 ml-1"
                  color="#466BE3"
                  text
                  ><u>Busque en su dispositivo</u></v-btn
                >
              </p>
              <p class="txt-commisions-ligth mb-1">Debe ser un archivo .PDF</p>
              <p class="txt-commisions-ligth">
                El peso límite del archivo es de 1 MB
              </p>
            </center>
          </div>

          <div v-else>
            <v-col cols="12" class="mx-0 px-0">
              <v-card class="card-file-upload" elevation="0">
                <v-row class="mx-0 px-0">
                  <v-col class="mx-0 px-0" cols="10">
                    <v-layout justify-center>
                      <img
                        width="53"
                        class="mt-3 ml-2"
                        src="../../assets/icon/upload.svg"
                      />
                    </v-layout>
                  </v-col>
                  <v-col class="mx-0 px-0" cols="2">
                    <v-btn
                      @click="form.file = null"
                      class="mt-1"
                      fab
                      icon
                      small
                    >
                      <v-icon color="#626262">
                        mdi-close-circle-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="text-left mt-0 pt-0">
                    <p class="mb-1 mt-0 txt-info-file-title">
                      {{ infoFile.name }}
                    </p>
                    <p class="mb-2 txt-info-file-subtitle">
                      {{ infoFile.size }} MB
                    </p>
                    <v-progress-linear
                      class="mb-4"
                      color="#466be3"
                      :height="5"
                      :indeterminate="loadFile"
                      stream
                      value="100"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </div>

          <p class="txt-upload-commision mb-4 pt-8">Complete los campos</p>
          <v-form ref="form">
            <v-autocomplete
              v-model="form.clientId"
              :items="listClient"
              label="Seleccione el cliente *"
              item-text="name"
              item-value="id"
              :rules="[(v) => !!v || 'Este campo es requerido']"
            ></v-autocomplete>
            <v-autocomplete
              v-model="form.campaignId"
              :items="listCampaign"
              label="Seleccione la campaña *"
              item-text="name"
              item-value="id"
              :rules="[(v) => !!v || 'Este campo es requerido']"
            ></v-autocomplete>
            <v-autocomplete
              v-model="form.profileId"
              :items="listProfile"
              label="Seleccione el cargo *"
              item-text="name"
              item-value="id"
              :rules="[(v) => !!v || 'Este campo es requerido']"
            ></v-autocomplete>
            <v-autocomplete
              v-model="form.contractor"
              :items="listContractor"
              label="Seleccione contratante *"
              :rules="[(v) => !!v || 'Este campo es requerido']"
            ></v-autocomplete>
          </v-form>
          <v-btn
            class="mb-15"
            @click="save()"
            block
            rounded
            color="#466BE3"
            dark
          >
            CARGAR CONDICIONES
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>

    <!-- <v-dialog v-model="message.dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5 request-project">
          <v-layout justify-center>
            {{ message.title }}
          </v-layout>
        </v-card-title>
        <v-card-text class="request-txt-project">
          {{ message.sms }}
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  name: "upload",
  data() {
    return {
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      dialog: false,
      show: 1,
      listClient: [],
      listProfile: [],
      listCampaign: [],
      listContractor: [],
      loadFile: true,
      infoFile: {
        name: "",
        size: "",
      },
      files: [], // quitar
      fit: true,
      loadingAll: false,
      form: {
        profileId: null,
        clientId: null,
        campaignId: null,
        file: null,
        contractorId: null,
      },
      token: "",
      name: "",
      infoToken: {
        document: "",
      },
      name: "Angie",
    };
  },
  watch: {
    "form.profileId": function (n) {
      this.dinamicFilter();
    },
    "form.clientId": function (n) {
      this.dinamicFilter();
    },
    "form.campaignId": function (n) {
      this.dinamicFilter();
    },
    "form.contractorId": function (n) {
      this.dinamicFilter();
    },
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    previewFile() {
      this.show = 1;
    },
    nextForm() {
      this.show = 2;
    },
    // getClients() {
    //   Api.MarginCommisions()
    //     .getClients(this.token)
    //     .then((res) => {
    //       if (res.data.cod == 0) {
    //         this.listClient = res.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    // getCampaigns() {
    //   Api.MarginCommisions()
    //     .getCampaigns(this.token)
    //     .then((res) => {
    //       if (res.data.cod == 0) {
    //         this.listCampaign = res.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // getContractors() {
    //   Api.MarginCommisions()
    //     .getContractor(this.token)
    //     .then((res) => {
    //       if (res.data.cod == 0) {
    //         this.listContractor = res.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // getProfile() {
    //   Api.MarginCommisions()
    //     .getProfile(this.token)
    //     .then((res) => {
    //       if (res.data.cod == 0) {
    //         this.listProfile = res.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    go() {
      this.$router.push({ name: "marginCommisions" });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loadingAll = true;

        const formData = new FormData();
        formData.append("commissionConditions", this.form.file);
        formData.append("clientId", this.form.clientId);
        formData.append("campaignId", this.form.campaignId);
        formData.append("profileId", this.form.profileId);
        formData.append("contractor", this.form.contractorId);

        Api.MarginCommisions()
          .uploadFile(formData, this.token)
          .then((res) => {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            if (res.data.cod == 0) {
              this.message.title = "Excelente";
              this.message.type = "success";
              this.message.redirect = "marginCommisions";
            } else {
              this.message.title = "¡Oh noo!";
              this.message.type = "error";
              this.message.redirect = "marginCommisions";
            }

            // this.message.dialog = true;
            // setTimeout(() => {
            //   this.message.dialog = false;
            //   this.$router.push({ name: "marginCommisions" });
            // }, 3000);

            // if (res.data.cod == 0) {
            //   this.message.title = "Muy bien";
            // } else {
            //   this.message.title = "Oops.. hay un error";
            // }
            // this.message.sms = res.data.message;
          })
          .catch((error) => {
            this.message.dialog = true;
            this.message.sms = error.response.data.message;
            this.message.title = "¡Oh no!";
            this.message.type = "error";
            this.message.redirect = "marginCommisions";
            // console.log(error.response);
            // this.message.dialog = true;
            // setTimeout(() => {
            //   this.message.dialog = false;
            // }, 3000);
            // this.message.title = "Oops.. hay un error";
            // if(error.response.status == 413) {
            //   this.message.sms = "El archivo a superado el límite del tamaño, deber ser  menor a 1MB";
            // }
            // this.message.sms = error.response.data.message;
          })
          .finally(() => {
            this.loadingAll = false;
          });
      }
    },
    handleClick(e) {
      this.loadFile = true;
      const files = e.target.files;
      const extension = files[0].type.split("/")[1].toLowerCase();
      const size = files[0].size;
      if (extension !== "pdf") {
        this.form.file = null;
        this.message.dialog = true;
        this.message.sms =
          "La extensión del archivo es inválido, debe ser un PDF";
        this.message.title = "¡Oh no!";
        this.message.type = "error";
        // setTimeout(() => {
        //   this.message.dialog = false;
        // }, 3000);
      } else if (size > 1034241) {
        this.form.file = null;
        this.message.dialog = true;
        this.message.sms = "El archivo debe pesar menos de 1MB";
        this.message.title = "¡Oh no!";
        this.message.type = "error";
        // setTimeout(() => {
        //   this.message.dialog = false;
        // }, 3000);
      } else {
        const rawFile = files[0]; // only use files[0]
        if (!rawFile) return;
        this.form.file = rawFile;
        this.infoFile.name = rawFile.name;
        this.infoFile.size = (rawFile.size / 1024 / 1024).toFixed(2);
        this.loadFile = false;
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      var extension = droppedFiles[0].type.split("/")[1].toLowerCase();
      if (extension !== "pdf") {
        this.message.dialog = true;
        this.message.sms =
          "La extensión del archivo es inválido, debe ser un PDF";
        this.message.title = "¡Oh no!";
        this.message.type = "error";
        // setTimeout(() => {
        //   this.message.dialog = false;
        // }, 3000);
      } else {
        const rawFile = droppedFiles[0];
        if (!rawFile) return;
        this.form.file = rawFile;
        this.infoFile.name = rawFile.name;
        this.infoFile.size = (rawFile.size / 1024 / 1024).toFixed(2);
        this.loadFile = false;
      }
    },
    dinamicFilter() {
      this.loadingAll = true;
      let data = {
        clientId: this.form.clientId,
        campaignId: this.form.campaignId,
        profileId: this.form.profileId,
        contractorId: this.form.contractorId,
      };
      Api.MarginCommisions()
        .dinamicFilter(data, this.token)
        .then((res) => {
          if (res.data.cod === 0) {
            this.listClient = [];
            this.listCampaign = [];
            this.listProfile = [];
            this.listContractor = [];
            res.data.data.forEach((element) => {
              let client = {
                id: element.client_id,
                name: element.client_name.toUpperCase(),
              };
              this.listClient.push(client);
              let campaign = {
                id: element.campaign_id,
                name: element.campaign_name.toUpperCase(),
              };
              this.listCampaign.push(campaign);
              let profile = {
                id: element.profile_id,
                name: element.profile_name.toUpperCase(),
              };
              this.listProfile.push(profile);
              this.listContractor.push(element.contractor.toUpperCase());
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.dinamicFilter();
  },
};
</script>
<style lang="less" scoped>
@import "./../../assets/css/main.less";

.mb-xl-margin-file {
  display: block;
}

.mb-sm-margin-file {
  display: none;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

#app2 {
  background: #ffffff;
  border: 1px dashed #466be3;
  border-radius: 15px;
  height: 353px;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}

.txt-info-file-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #626262;
}

.txt-info-file-subtitle {
  font-family: "RobotoMedium";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #626262;
}

.txt-upload-commision {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 25px;
  color: #353535;
}
.txt-subtitle-commisions {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #353535;
}
.txt-commisions-ligth {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #626262;
}
.request-project {
  color: #466be3;
  font-family: "RobotoMedium" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
}

.request-txt-project {
  font-family: "RobotoRegular";
  color: #898989 !important;
  font-weight: 300;
}
.card-file-upload {
  background: #ffffff !important;
  border: 1px solid #466be3 !important;
  border-radius: 10px !important;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #c6c6c6 !important;
  color: #ffffff !important;
}

.upload-file__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin-file {
    display: none;
  }
  .mb-sm-margin-file {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }
  .mb-xl-margin-file {
    display: none;
  }
  .mb-sm-margin-file {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-margin-file {
    display: none;
  }
  .mb-sm-margin-file {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }

  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-margin-file {
    display: none;
  }
  .mb-sm-margin-file {
    display: block;
    text-align: initial;
  }
}
</style>
